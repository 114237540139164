import React from 'react';
import { Text, Title } from '../Typography';

import { Container, ImageSection, ContentSection, SubHeading, Heading } from './styles';

import { CorpPressStoriesCardType } from './types';
import Picture from '../../modules/responsivePicture/components/Picture';
import { getPictureSources } from '../../modules/getPictureSources';
import clsx from 'clsx';
import { CorpCardCarouselVariant } from 'generated/graphql';
import Link from 'next/link';
import { SeeMoreCardArrow } from 'components/Icons/icons';

export const CorpPressStoriesCard = ({
  type,
  cardData,
  className,
  copyDictionary,
  cardVariant = CorpCardCarouselVariant.neutral,
}: {
  type: 'story' | 'press';
  copyDictionary: any[];
  cardData?: CorpPressStoriesCardType;
  className?: string;
  cardVariant?: CorpCardCarouselVariant;
}) => {
  const getCopy = (key: string) =>
    copyDictionary?.filter((item: any) => item.key === key)[0]?.value;
  const CardVariant = cardVariant ?? CorpCardCarouselVariant.neutral;
  return (
    <Container
      href={cardData?.slug}
      target="_self"
      className={clsx(
        {
          'bg-corporate-blue text-white': CardVariant === CorpCardCarouselVariant.primary,
          'bg-black text-darkGray': CardVariant === CorpCardCarouselVariant.neutral,
        },
        className,
      )}
    >
      {cardData?.image && (
        <ImageSection cardVariant={CardVariant}>
          <Picture sources={getPictureSources(cardData?.image)} alt="" />
        </ImageSection>
      )}
      <ContentSection
        className={clsx({
          'bg-corporate-blue text-white': CardVariant === CorpCardCarouselVariant.primary,
          'bg-corporate-offWhite text-darkGray': CardVariant === CorpCardCarouselVariant.neutral,
        })}
      >
        <div>
          {cardData?.tag && (
            <Text tag="div" type="lead">
              {getCopy(
                type === 'press' ? `press_tag_${cardData?.tag}` : `story_tag_${cardData?.tag}`,
              )}
            </Text>
          )}
          {cardData?.date && (
            <SubHeading tag="div" type="lead">
              {new Date(cardData?.date).toLocaleDateString('de-de', {
                day: '2-digit',
                month: '2-digit',
                year: 'numeric',
              })}
            </SubHeading>
          )}
          {cardData?.title && (
            <Heading tag="div" type="xs">
              {cardData?.title}
            </Heading>
          )}
          {cardData?.buttonLink && (
            <div className="flex mx-auto w-20 justify-between bg-black">
              <Link
                href={cardData?.buttonLink?.url ?? ''}
                target={cardData?.buttonLink?.openInNewTab ? '_blank' : '_self'}
              >
                <Text tag="p" type="l">
                  {cardData.buttonLink?.displayName}
                </Text>
              </Link>
              <SeeMoreCardArrow />
            </div>
          )}
        </div>
      </ContentSection>
    </Container>
  );
};
CorpPressStoriesCard.defaultProps = {
  metaBall: false,
};
